<template>
  <div>
    <!-- Header -->
    <div class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          <span v-if="shallShowMessageComposeModal == 'list'">
            {{ $t('messages') }}
          </span>
          <span v-if="shallShowMessageComposeModal == 'create'">
            {{ $t('conversations.labels.newMessage') }}
          </span>
          <span v-else-if="shallShowMessageComposeModal == 'details'">
            {{ conversationDetail.name == null ? conversationDetail.guests[0].full_name : conversationDetail.name }}
          </span>
        </h4>
        <transition
          name="fade"
          mode="out-in"
        >
          <b-button
            v-if="shallShowMessageComposeModal == 'list'"
            key="plusIcon"
            variant="primary"
            class="btn-icon rounded-circle"
            :style="colorPrimaryBtn"
            @click="resetForm(true)"
          >
            <i class="icon-0-icons-dark-plus" />
          </b-button>
          <div v-else>
            <template v-if="shallShowMessageComposeModal == 'details'">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                class="btn-icon-f rounded-circle"
                @click="changeStatusBtn(!showTrashButton)"
              >
                <FeatherIcon icon="CheckSquareIcon" />
              </b-button>
              <b-button
                v-if="showTrashButton"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                class="btn-icon rounded-circle ml-1"
                @click="removeMessageMain()"
              >
                <i class="icon-0-icons-dark-trash-can" />
              </b-button>
            </template>
            <b-button
              key="arrowLeftIcon"
              variant="primary"
              :style="colorPrimaryBtn"
              class="btn-icon rounded-circle ml-1"
              @click="onAddMessage"
            >
              <i class="icon-0-icons-dark-arrow-left" />
            </b-button>
          </div>
        </transition>
      </div>
    </div>

    <!-- Message -->
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="shallShowMessageComposeModal == 'list'"
        key="messageNotification"
      >
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="scrollable-container media-list scroll-area"
          tagname="li"
        >
          <!-- Message Notification -->
          <div
            v-for="item in conversationList"
            :key="item.hash"
            :class="conversationList.length > 4 ? 'i-padding-message' : ''"
          >
            <div
              class="cursor-pointer"
              @click="handleShowMessage(item.hash, item)"
            >
              <b-media v-if="item.name == null">
                <template #aside>
                  <b-avatar
                    v-if="item.guests[0].avatar_media_file != null"
                    size="32"
                    :src="path + item.guests[0].avatar_media_file.url_relative"
                  />
                  <b-avatar
                    v-else
                    variant="primary"
                    :text="item.guests[0].initials"
                  />
                </template>
                <p class="media-heading">
                  <span class="font-weight-bolder">
                    {{ item.guests[0].full_name }}
                  </span>
                </p>
                <small class="notification-text cursor-pointer">
                  <div class="i-text-content">
                    {{ item.messages.content.substring(0,100)+"..." }}
                  </div>
                </small>
                <br>
                <div class="d-flex align-items-center justify-content-end">
                  <b-avatar
                    :badge="item.notification_counter > 0 ? String(item.notification_counter) : null"
                    size="28px"
                    variant="light-secondary"
                    badge-top
                    badge-variant="danger"
                    class="ml-1 cursor-pointer"
                  >
                    <feather-icon icon="MessageSquareIcon" />
                  </b-avatar>
                </div>
              </b-media>
              <b-media v-else>
                <template #aside>
                  <b-avatar variant="secondary">
                    <i class="icon-0-icons-dark-contacts i-size-icon"/>
                  </b-avatar>
                </template>
                <p class="media-heading">
                  <span class="font-weight-bolder">
                    {{ item.name }}
                  </span>
                </p>
                <small class="notification-text cursor-pointer">
                  <div class="i-text-content">
                    {{ item.messages.content.substring(0,100)+"..." }}
                  </div>
                </small>
                <br>
                <div class="d-flex align-items-center justify-content-end">
                  <b-avatar
                    :badge="item.notification_counter > 0 ? String(item.notification_counter) : null"
                    size="28px"
                    variant="light-secondary"
                    badge-top
                    badge-variant="danger"
                    class="ml-1 cursor-pointer"
                  >
                    <feather-icon icon="MessageSquareIcon" />
                  </b-avatar>
                </div>
              </b-media>
            </div>
          </div>
        </vue-perfect-scrollbar>
      </div>
      <!-- write message -->
      <div
        v-if="shallShowMessageComposeModal == 'create' "
        key="messageWrite"
      >
        <message-compose
          v-model="shallShowMessageComposeModal"
          :message="messageForm"
          :color-primary-btn="colorPrimaryBtn"
          @on-add-message="onAddMessage"
          @cancel-add-message="cancellAddMessage"
        />
      </div>
      <div
        v-if="shallShowMessageComposeModal == 'details'"
        key="messageDetails"
      >
        <message-details
          v-model="shallShowMessageComposeModal"
          :message-list="messageList"
          :conversation-detail="conversationDetail"
          :auth-user-id="authUserId"
          :path="path"
          :show-trash-button="showTrashButton"
          @on-add-message="onAddMessage"
          @on-delete-message="deleteMessage"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { formatDateToMonthShort } from '@core/utils/filter'
import messageService from '@/services/messageService'
import MessageCompose from './MessageCompose.vue'
import MessageDetails from './MessageDetails.vue'

export default {
  components: {
    VuePerfectScrollbar,
    MessageCompose,
    MessageDetails,
  },

  directives: {
    Ripple,
  },

  props: {
    messageNumber: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      auxCounter: 0,
      nextPage: null,
      messageId: null,
      conversationDetail: null,
      shallShowMessageComposeModal: 'list',
      showTrashButton: false,
      conversationList: [],
      messageList: [],
      messageForm: {
        message_type: null,
        apply_group: false,
        group_name: '',
        include_everyone: 0,
        guests: [],
        exclude_guests: [],
        all: null,
        content: '',
        user_id: null,
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      paginate: {
        from: 1,
        to: 4,
        total: 0,
      },
    }
  },

  watch: {
    messageNumber() {
      this.fetchConversations()
    },
  },

  computed: {
    ...mapGetters({
      authUserId: 'id',
      colorPrimaryBtn: 'colorPrimaryBtn',
      path: 'path',
    }),
  },

  mounted() {
    this.fetchConversations()
    this.resetForm(false)
  },

  methods: {
    formatDateToMonthShort,
    ...mapActions({
      fetchMessageById: 'message/fetchMessageById',
      removeMessage: 'message/remove',
      removeComment: 'message/removeComment',
    }),

    fetchConversations() {
      const params = {
        perPage: this.paginate.to,
        page: this.paginate.from,
      }

      this.conversationList = []

      messageService.getConversations(params).then(({ data }) => {
        this.auxCounter = 0
        data.data.forEach(function (e) {
          if (e.messages != null) {
            this.conversationList.push(e)
            this.auxCounter += e.notification_counter
          }
        }, this)

        this.nextPage = data.next_page_url
        this.$emit('assign-counter-value', this.auxCounter)

        // setTimeout(() => {
        //   const paramsa = {
        //     perPage: 4,
        //     page: 2,
        //   }
        //   messageService.getConversations(paramsa).then(respon => {
        //     respon.data.data.forEach(function (e) {
        //       if (e.messages != null) {
        //         this.conversationList.push(e)
        //       }
        //     }, this)
        //
        //     setTimeout(() => {
        //       const paramsb = {
        //         perPage: 4,
        //         page: 3,
        //       }
        //       messageService.getConversations(paramsb).then(resp => {
        //         resp.data.data.forEach(function (e) {
        //           if (e.messages != null) {
        //             this.conversationList.push(e)
        //           }
        //         }, this)
        //       }).catch(error => {
        //         this.responseCatch(error)
        //       })
        //     }, 1000)
        //   }).catch(error => {
        //     this.responseCatch(error)
        //   })
        // },10000)
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    // fetchImage(userList) {
    //   const userGuest = userList.filter(us => us.hash != this.id)
    //   const img = userGuest[0].avatar_media_file == null ? null : userGuest[0].avatar_media_file.url_relative
    //   return this.path + img
    // },

    handleShowMessage(conversationId, pItem) {
      this.conversationDetail = pItem
      messageService.getMessagesByConversation(conversationId).then(({ data }) => {
        this.messageList = data.data
        this.shallShowMessageComposeModal = 'details'
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    removeMessageMain() {
      this.sampleDeleteQuestion().then(result => {
        const filteredList = this.messageList.messages.filter(m => m.selected)

        if (!result.isConfirmed) {
          return
        }

        const mssgList = filteredList.map(fl => ({ message_hash: fl.hash }))
        const conversationIdB = this.messageList.hash

        const messageData = {
          user_id: this.authUserId,
          messages: mssgList,
        }

        messageService.deleteMessages(conversationIdB, messageData).then(({ data }) => {
          this.onAddMessage()
        }).catch(error => {
          this.responseCatch(error)
        })
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    async onAddMessage() {
      this.showTrashButton = false
      this.shallShowMessageComposeModal = 'list'
      this.fetchConversations()
    },

    cancellAddMessage() {
      this.shallShowMessageComposeModal = 'list'
    },

    async deleteMessage(commentData) {
      this.showDeleteWarning(this.$t('deleted-message')).then(result => {
        if (!result.isConfirmed) {
          this.removeComment(commentData).then(response => {
            this.handleShowMessage(this.messageId, null)
          }).catch(error => {
            this.responseCatch(error)
          })
        }
      })
    },

    resetForm(changeAction) {
      if (changeAction) {
        this.shallShowMessageComposeModal = 'create'
      }

      this.messageForm = {
        message_type: null,
        apply_group: false,
        group_name: '',
        include_everyone: 0,
        guests: [],
        exclude_guests: [],
        all: null,
        content: '',
        user_id: this.authUserId,
      }
    },

    changeStatusBtn(pValue) {
      this.showTrashButton = pValue
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-email.scss";
.media {
  position: relative;
  &:hover {
    .message-button-reply {
      visibility: visible;
    }
  }
}
.message-button-reply {
  position: absolute;
  bottom: 15px !important;
  right: 17px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  // visibility: hidden;
  @include media-breakpoint-up(md) {
    visibility: hidden;
  }
}
.btn.btn-icon {
  padding: 0.715rem 0.536rem;
}
.btn.btn-icon-f {
  padding: 0.715rem 0.715rem;
}
.i-padding-message {
  padding: 0 13px 2px 0;
}
.i-text-content {
  display: flex;
  align-items: center;
  word-break:break-all;
}
</style>
