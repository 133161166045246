<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-md-inline-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0 text-dark">
          {{ fullName }}
        </p>
        <span class="user-status text-dark role-name">{{ role.name }}</span>
      </div>
      <b-avatar
        size="40"
        :src="path+avatar"
        variant="light-dark"
        badge
        class="badge-minimal d-md-inline-flex d-none i-shadow-new"
        badge-variant="success"
      >
        <i
          v-if="!avatar"
          class="icon-0-icons-dark-avatar i-icon-size-avatar"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="getProfileView"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>{{ $t('accountSettings.profile') }}</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click.once="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>{{ $t('accountSettings.logout') }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { initialAbility } from '@/libs/acl/config'
import { mapGetters, mapActions } from 'vuex'
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },

  computed: {
    ...mapGetters({
      id: 'id',
      fullName: 'fullName',
      avatar: 'avatar',
      role: 'role',
      path: 'path',
    }),
  },

  methods: {
    ...mapActions([
      'setColorValueSidebar',
      'setColorValue',
      'deleteSessionInfo',
      'setCurrentClub',
      'setClubsByUser',
    ]),

    getProfileView() {
      if (this.$route.name != 'ac-general-info' && this.$route.name != 'ac-address-and-contact' && this.$route.name != 'ac-change-password') {
        this.$router.push({ name: 'account-settings', params: { id: this.id } })
      }
    },

    logout() {
      this.$ability.update(initialAbility)
      this.deleteSessionInfo()
      this.setCurrentClub({})
      this.setClubsByUser([])

      setTimeout(() => {
        sessionStorage.removeItem('web-page')
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')

        localStorage.removeItem('vuex')
        localStorage.clear()

        // Reset ability

        this.setColorValueSidebar('#33334d')
        this.setColorValue('#6abeff')

        // Redirect to login page
        this.$router.replace({ name: 'auth-login' })
      }, 200)
    },
  },
}
</script>

<style scoped>
.i-icon-size-avatar {
  font-size: 15px;
}
.role-name {
  overflow: hidden;
    white-space: nowrap;
    width: 40%;
}
</style>
